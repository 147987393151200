import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useContext, useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useFormik } from "formik";
import * as Yup from "yup";
import CourseTemplateActions from "../../../Actions/ConfigActions/CourseTemplateActions";
import { CourseTemplateContext } from "../../../Contexts/ConfigContext/CourseTemplateContext";
import { LoaderContext } from "../../../Contexts/LoaderContext";
import { toastError, toastSuccess } from "../../../Helpers/toasts";
import CourseTemplateRepository from "../../../Repositories/Config/CourseTemplateRepository";
import { loadItems } from "./actions";
import AddPriceItem from "./components/AddPriceItem";
import BackBtn from "./components/BackBtn";
import { useTranslation } from "react-i18next";

export default function CourseTemplateModify() {
  const [saveLoader, setSaveLoader] = useState(false);
  const { courseTemplate, dispatchCourseTemplate } = useContext(
    CourseTemplateContext
  );
  const { t } = useTranslation();
  const { dispatchLoader } = useContext(LoaderContext);
  const DeleteButton = styled(Button)({
    width: "100px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    lineHeight: "12px",
  });
  const initialValues = {
    age_group: "",
    duration: "",
    lessons_per_week: "",
    number_of_lessons: "",
    subject: "",
    branch: "",
    course_prices: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: false,
    validationSchema: Yup.object({
      branch: Yup.string()
        .min(1, t("required_subject"))
        .required(t("required_subject")),
      subject: Yup.string()
        .min(1, t("required_subject"))
        .required(t("required_subject")),
      age_group: Yup.string()
        .min(1, t("required_group"))
        .required(t("required_group")),
      duration: Yup.number().required(t("required_course_duration")),
      lessons_per_week: Yup.number().required(t("required_lesson_plan")),

      course_prices: Yup.array().of(
        Yup.object().shape({
          month: Yup.number(t("only_number"))
            .min(1, t("required_month"))
            .required(t("required_month")),
          price: Yup.string(t("only_string"))
            .min(1, t("required_month_price"))
            .required(t("required_month_price")),
        })
      ),
    }),
    onSubmit: (values) => {
      setSaveLoader(true);
      if (courseTemplate.editingId !== null) {
        const data = {
          ...values,
          removed_course_prices: [
            ...courseTemplate.item.removed_course_prices,
          ].toString(),
        };
        updateItem(data, courseTemplate.editingId);
      } else {
        createItem(values);
      }
    },
  });

  const handleChangeWeek = useCallback((e) => {
    const coast = e.target.value * formik.values.duration * 4;
    formik.setFieldValue("number_of_lessons", coast);
  });

  const handleChangeDuration = useCallback((e) => {
    const coast = e.target.value * formik.values.lessons_per_week * 4;
    formik.setFieldValue("number_of_lessons", coast);
  });

  const cancelAction = useCallback(() => {
    dispatchCourseTemplate({
      type: CourseTemplateActions.CANCEL_BUTTON_CLICKED,
    });
  });

  useEffect(() => {
    if (courseTemplate.isModalOpen == true) {
      formik.resetForm();
      if (formik.values.course_prices.length > 0) {
        formik.values.course_prices.splice(0);
      }
      if (formik.values.course_prices.length === 0) {
        formik.values.course_prices.push({ month: 1, price: "" });
      }
    }
  }, [courseTemplate.isModalOpen]);

  useEffect(() => {
    for (const [key, value] of Object.entries(courseTemplate.item)) {
      if (key === "course_prices") {
        value.forEach((element, index) => {
          let priceString = element.price.toString();
          formik.setFieldValue(
            `course_prices.${index}.id`,
            element["id"],
            false
          );
          formik.setFieldValue(
            `course_prices.${index}.month`,
            element["month"],
            false
          );
          formik.setFieldValue(
            `course_prices.${index}.price`,
            priceString,
            false
          );
        });
      } else {
        formik.setFieldValue(key, courseTemplate.item?.[key], false);
      }
    }
  }, [courseTemplate.isEditing]);

  const createItem = useCallback(async (data) => {
    const responseData = await CourseTemplateRepository.createCourse(data);
    setSaveLoader(false);
    if (responseData.status === true) {
      toastSuccess(t("success_open_course"));
      loadItems({}, courseTemplate, dispatchCourseTemplate, dispatchLoader);
      cancelAction();
    } else {
      toastError(responseData.nonFieldErrors);
    }
  });
  const updateItem = useCallback(async (data, id) => {
    const responseData = await CourseTemplateRepository.updateCourse(data, id);
    setSaveLoader(false);
    if (responseData.status === true) {
      toastSuccess(t("success_update_course"));
      loadItems({}, courseTemplate, dispatchCourseTemplate, dispatchLoader);
      cancelAction();
    } else {
      toastError(responseData.nonFieldErrors);
    }
  });

  const handleDeletePrice = useCallback((item) => {
    let found = formik.values.course_prices.filter((i) => i === item);
    let index = formik.values.course_prices.indexOf(found);
    formik.values.course_prices.splice(index, 1);
    const deletepricefilter = courseTemplate.item.course_prices.filter(
      (i) => i.id === item.id
    );
    if (courseTemplate.isEditing && deletepricefilter.length) {
      dispatchCourseTemplate({
        type: CourseTemplateActions.COURSE_PRICE_REMOVED,
        payload: { id: item.id },
      });
    }
  });

  return (
    <div
      className={
        courseTemplate.isModalOpen
          ? "course_container active_course_form"
          : "course_container"
      }
    >
      <form action="" className="course_form" onSubmit={formik.handleSubmit}>
        <BackBtn action={() => cancelAction()} />
        <h4 className="modal__title course_title">{t("enter_course")}</h4>
        <div
          style={{ flexWrap: "wrap" }}
          className="modal-select__item course-modal_input"
        >
          <div className="modal-select__box course_field_width_control">
            <label className="course_label" htmlFor="branch">
              {t("branch")}
            </label>
            <select
              id="branch"
              className={
                formik.touched.branch && formik.errors.branch
                  ? "modal-input error_input"
                  : "modal-input"
              }
              name="branch"
              value={formik.values.branch}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option
                className="course_disabled_option"
                value=""
                disabled
                hidden
              ></option>
              {courseTemplate.branches.map((branch) => (
                <option value={branch.id} key={branch.id}>
                  {branch.title}
                </option>
              ))}
            </select>
            {formik.touched.branch && formik.errors.branch ? (
              <p className="input-error-message">{formik.errors.branch}</p>
            ) : null}
          </div>
          <div className="modal-select__box course_field_width_control">
            <label className="course_label" htmlFor="subject">
              {t("subject")}
            </label>
            <select
              id="subject"
              className={
                formik.touched.subject && formik.errors.subject
                  ? "modal-input error_input"
                  : "modal-input"
              }
              name="subject"
              value={formik.values.subject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option
                className="course_disabled_option"
                value=""
                disabled
                hidden
              ></option>
              {courseTemplate.subjects.map((subject) => (
                <option value={subject.id} key={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
            {formik.touched.subject && formik.errors.subject ? (
              <p className="input-error-message">{formik.errors.subject}</p>
            ) : null}
          </div>
          <div className="modal-select__box course_field_width_control">
            <label className="course_label" htmlFor="group">
              {t("group_type")}
            </label>
            <select
              id="group"
              className={
                formik.touched.age_group && formik.errors.age_group
                  ? "modal-input error_input"
                  : "modal-input"
              }
              name="age_group"
              value={formik.values.age_group}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option
                className="course_disabled_option"
                value=""
                disabled
                hidden
              ></option>

              {courseTemplate.groupTypes.map((groupType) => (
                <option value={groupType[0]} key={groupType[0]}>
                  {groupType[1]}
                </option>
              ))}
            </select>
            {formik.touched.age_group && formik.errors.age_group ? (
              <p className="input-error-message">{formik.errors.age_group}</p>
            ) : null}
          </div>
          <div className="modal-select__box course_field_width_control">
            <label className="course_label" htmlFor="subject-time">
              {t("lesson_plan")}
            </label>
            <input
              id="subject-time"
              className={
                formik.touched.lessons_per_week &&
                formik.errors.lessons_per_week
                  ? "modal-input error_input"
                  : "modal-input"
              }
              type="number"
              name="lessons_per_week"
              value={formik.values.lessons_per_week}
              onChange={formik.handleChange}
              onKeyUp={(e) => handleChangeWeek(e)}
              onBlur={formik.handleBlur}
            />
            {formik.errors.lessons_per_week ? (
              <p className="input-error-message">
                {formik.touched.lessons_per_week &&
                  formik.errors.lessons_per_week}
              </p>
            ) : null}
          </div>
          <div className="modal-select__box course_field_width_control">
            <label className="course_label" htmlFor="general-time">
              {t("course_duration")}
            </label>
            <input
              id="general-time"
              className={
                formik.touched.duration && formik.errors.duration
                  ? "modal-input error_input"
                  : "modal-input"
              }
              name="duration"
              value={formik.values.duration}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyUp={(e) => handleChangeDuration(e)}
              type="number"
            />
            {formik.errors.duration ? (
              <p className="input-error-message">
                {formik.touched.duration && formik.errors.duration}
              </p>
            ) : null}
          </div>
          <div className="modal-select__box course_field_width_control">
            <label className="course_label" htmlFor="all-time">
              {t("lesson_count")}
            </label>
            <input
              id="all-time"
              className="modal-input"
              name="number_of_lessons"
              type="number"
              disabled={true}
              value={formik.values.number_of_lessons}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.errors.number_of_lessons ? (
              <p className="input-error-message">
                {formik.touched.number_of_lessons &&
                  formik.errors.number_of_lessons}
              </p>
            ) : null}
          </div>
        </div>
        <div className="course_line"></div>
        <h4 className="course_title_price">{t("enter_payment")}</h4>
        <div className="price_item">
          {formik.values.course_prices.map((item, index) => {
            return (
              <div className="price_block" key={index}>
                <div className="price_block_input">
                  <label htmlFor="start-time" className="course_label">
                    {t("payment_month")}
                  </label>
                  <input
                    id="start-time"
                    className={
                      formik.touched.course_prices?.[index]?.month &&
                      formik.errors.course_prices?.[index]?.month
                        ? "modal-input number-rule error_input"
                        : "modal-input number-rule"
                    }
                    type="number"
                    name={`course_prices.${index}.month`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.course_prices?.[index].month}
                  />
                  {formik.touched.course_prices?.[index]?.month &&
                  formik.errors.course_prices?.[index]?.month ? (
                    <p className="input-error-message">
                      {formik.errors.course_prices?.[index]?.month}
                    </p>
                  ) : null}
                </div>
                <div className="price_block_input">
                  <label htmlFor="end-time" className="course_label">
                    {t("payment_price")}
                  </label>
                  <input
                    id="end-time"
                    className={
                      formik.touched.course_prices?.[index]?.price &&
                      formik.errors.course_prices?.[index]?.price
                        ? "modal-input number-rule error_input"
                        : "modal-input number-rule"
                    }
                    name={`course_prices.${index}.price`}
                    type="text"
                    value={formik.values.course_prices[index].price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.course_prices?.[index]?.price &&
                  formik.errors.course_prices?.[index]?.price ? (
                    <p className="input-error-message">
                      {formik.errors.course_prices?.[index]?.price}
                    </p>
                  ) : null}
                </div>
                <div
                  className={
                    formik.values.course_prices.length === index + 1
                      ? "delete_price"
                      : "d-none"
                  }
                >
                  <DeleteButton
                    type="button"
                    size="small"
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDeletePrice(item)}
                  >
                    {t("delete")}
                  </DeleteButton>
                </div>
              </div>
            );
          })}
          <AddPriceItem coursePrice={formik.values} />
        </div>
        <div className="input-buttons">
          <div className="input-btn course_btn" onClick={() => cancelAction()}>
            {t("cancel")}
          </div>
          <button
            type="submit"
            className="input-btn input-btn--submit "
            disabled={saveLoader}
          >
            {saveLoader ? (
              <ReactLoading
                type={"spin"}
                color="#ffffff"
                className="delete_loader"
                height={"30px"}
                width={"30px"}
              />
            ) : (
              `${t("submit")}`
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
