import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { MainGroupsContext } from "../../../../Contexts/GroupsContext/MainGroupsContext";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { useMainGroupStore } from "../../../../Store/ManageGroupsStore/MainGroupStore";
import { filterGroups } from "../actions";
import Selector from "./Select";
const container = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
  outline: "none",
  padding: "30px 60px",
  width: { xs: "90%", sm: 700 },
};
const style = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row" },
  flexWrap: "wrap",
  justifyContent: "space-between",
};
const borderElement = {
  margin: "10px 0",
  display: "flex",
  flexDirection: "column",
  gap: "10px 0",
};
const btnSecton = {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
};

export default function FilteredModal({ data }) {
  const { mainGroups, dispatchMainGroups } = useContext(MainGroupsContext);
  const { dispatchLoader } = useContext(LoaderContext);
  const { isOpenModal, setModalToggler } = useMainGroupStore();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    setModalToggler(false);
    await filterGroups(
      { ...mainGroups?.queryParams, page: 1 },
      dispatchMainGroups,
      dispatchLoader
    );
  };

  const subjects = useMemo(() => {
    return (
      mainGroups?.subjects?.map((item) => ({
        label: item.name,
        value: item.subject_id,
      })) || []
    );
  }, [mainGroups.subjects]);

  const statuses = useMemo(() => {
    return (
      mainGroups?.group_statuses?.map((item) => ({
        label: item.verbose_name,
        value: item.value,
      })) || []
    );
  }, [mainGroups.group_statuses]);
  const months = useMemo(() => {
    return (
      mainGroups?.months?.map((item) => ({
        label: item.verbose_name,
        value: item.value,
      })) || []
    );
  }, [mainGroups.group_statuses]);

  const groupTypes = useMemo(() => {
    return (
      mainGroups?.filterGroupsType?.map((item) => ({
        label: item[1],
        value: item[0],
      })) || []
    );
  }, [mainGroups.filterGroupsType]);

  const days = useMemo(() => {
    return (
      mainGroups?.weekDays?.map((item) => ({
        label: item.verbose_name,
        value: item.recurring_days.join(","),
      })) || []
    );
  }, [mainGroups.weekDays]);

  const branches = useMemo(() => {
    return (
      mainGroups?.branches?.map((item) => ({
        label: item.title,
        value: item.id,
      })) || []
    );
  }, [mainGroups.branches]);

  const staffs = useMemo(() => {
    return (
      mainGroups?.staffs?.map((item) => ({
        label: item.fullname,
        value: item.id,
      })) || []
    );
  }, [mainGroups.branches]);

  return (
    <div>
      <Modal
        className="filterModal"
        onClick={(e) => e.stopPropagation()}
        open={isOpenModal}
        onClose={() => setModalToggler(false)}
        aria-describedby="modal-modal-description"
      >
        <Box sx={container}>
          <Box sx={style}>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Guruh holati bo'yicha")}
              </Typography>
              <Selector
                property={t("Guruh statusi")}
                param={statuses}
                value={statuses?.find(
                  (item) => item.value == mainGroups.queryParams.status
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Oylar bo'yicha")}
              </Typography>
              <Selector
                property={t("months")}
                param={months}
                value={months?.find(
                  (item) => item.value == mainGroups.queryParams.month
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Fanlar bo'yicha saralash")}
              </Typography>
              <Selector
                property={t("Fanlar")}
                param={subjects}
                value={subjects?.find(
                  (item) => item.value == mainGroups.queryParams.subject
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Guruh turi bilan saralash")}
              </Typography>
              <Selector
                property={t("Guruh turlari")}
                param={groupTypes}
                value={groupTypes?.find(
                  (item) => item.value == mainGroups.queryParams.group_type
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Dars kunlari bilan saralash")}
              </Typography>
              <Selector
                property={t("Dars kunlari")}
                param={days}
                value={days?.find(
                  (item) => item.value == mainGroups.queryParams.recurring_days
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Binolar bilan saralash")}
              </Typography>
              <Selector
                property={t("Binolar")}
                param={branches}
                value={branches?.find(
                  (item) => item.value == mainGroups.queryParams.branch
                )}
              />
            </Box>
            <Box sx={borderElement}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                fontSize={18}
              >
                {t("Xodim bilan saralash")}
              </Typography>
              <Selector
                property={t("Xodimlar")}
                param={staffs}
                value={staffs?.find(
                  (item) => item.value == mainGroups.queryParams.teacher
                )}
              />
            </Box>
          </Box>
          <Box sx={btnSecton}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              endIcon={<FilterAltIcon />}
            >
              Filter
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
