import React, { useContext, useState } from "react";
import { LoaderContext } from "../../../../Contexts/LoaderContext";
import { EmployeeRegistrationContext } from "../../../../Contexts/RegistrationContex/EmployeeRegistrationContext";
import { filterStaffs, openFilterList } from "../actions";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const filter_btn = {
  height: "35px !important",
  fontFamily: "Roboto,sans-serif !important",
  background: "#f6f6f6 !important",
  border: "1px solid #cccccc !important",
  borderRadius: "4px !important",
  color: "#080b22 !important",
  textTransform: "capitalize !important",
  display: "flex !important",
  justifyContent: "center !important",
  alignItems: "center !important",
  padding: "0 20px !important",
  fontSize: "13px !important",
  fontFamily: "sans-serif",
};

export default function FilterBlock() {
  const { employee, dispatchEmployee } = useContext(
    EmployeeRegistrationContext
  );

  const { dispatchLoader } = useContext(LoaderContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    openFilterList(dispatchEmployee);
  };

  const handleClose = async (prop) => {
    setAnchorEl(null);
    await filterStaffs(prop, dispatchEmployee, dispatchLoader);
  };
  const onClose = () => {
    setAnchorEl(null);
    openFilterList(dispatchEmployee);
  };

  return (
    <div>
      <Button
        startIcon={
          <FilterAltIcon
            className="student_filter_icon"
            fontSize="11px"
            color="action"
          />
        }
        size="small"
        sx={filter_btn}
        onClick={handleClick}
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
      >
        Filter
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        TransitionComponent={Fade}
      >
        {employee.working_statuses?.map((item) => {
          return (
            <MenuItem onClick={() => handleClose(item.value)}>
              {item.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
