import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import "typeface-inter";
import { useTranslation } from "react-i18next";
export default function ListItemTablePrice(context) {
  const StyledTableCellHeader = styled(TableCell)({
    color: "#111",
    fontFamily: "Inter",
    padding: "7px 30px",
    fontSize: "12px",
    fontWeight: "700",
    textAlign: "center",
  });
  const StyledTableCell = styled(TableCell)({
    color: "#111",
    fontFamily: "Inter",
    fontSize: "11px",
    padding: "7px 32px",
    textAlign: "center",
  });
  const { t } = useTranslation();

  return (
    <Paper
      sx={{
        width: {
          xs: "100%",
          sm: "75%",
          md: "60%",
          lg: "50%",
        },
      }}
    >
      <TableContainer sx={{ maxHeight: 210 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCellHeader align="center" colSpan={2}>
                {t("months_payment")}
              </StyledTableCellHeader>
            </TableRow>
            <TableRow>
              <StyledTableCellHeader>{t("months")}</StyledTableCellHeader>
              <StyledTableCellHeader>
                {t("payment_month_amount")}
              </StyledTableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {context.course_price?.map((item) => {
              return (
                <TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
                  <StyledTableCell>{item.month} - oy </StyledTableCell>
                  <StyledTableCell>{item.price} so'm</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
