import { Box } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EmptyState from "../../../../../Components/Empty-content/EmptyState";
import { useRefundStore } from "../../../../../Store/CashControlStore/RefundStore";
import GroupInfo from "./GroupInfo";
import PaymentSection from "./PaymentSection";
import Selector from "./Select";

export default function GroupsContainer() {
  const { t } = useTranslation();
  const { lesson_months, groupDetails, salary } = useRefundStore();
  const groupOptions = useMemo(() => {
    return (
      lesson_months?.map((element) => ({
        label: element.name,
        value: element,
      })) || []
    );
  }, [lesson_months]);

  const handleChangeGroupDetails = (e) => {
    useRefundStore.setState({
      groupDetails: e?.value
        ? lesson_months.find((item) => item.id == e?.value.id)
        : null,
      salary: {
        ...salary,
        lesson_month: e?.value.id,
        group: e?.value.group_id,
      },
    });
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Selector
          options={groupOptions}
          value={
            groupOptions?.find((item) => item.value === groupDetails) || null
          }
          property={t("Haqdor guruhlari")}
          handleChange={(e) => handleChangeGroupDetails(e)}
        />
      </Box>
      {groupDetails ? (
        <>
          <h1 className="student_information">{t("Haqdor guruhlari")}</h1>
          <GroupInfo />
          <PaymentSection />
        </>
      ) : (
        <EmptyState />
      )}
    </>
  );
}
