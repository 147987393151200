import * as React from "react";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import { Alert, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { approachingExams, subGroups } from "../actions";
import { MainGroupsContext } from "../../../../Contexts/GroupsContext/MainGroupsContext";
import { useCallback } from "react";
import { useMainGroupStore } from "../../../../Store/ManageGroupsStore/MainGroupStore";
import { toastError } from "../../../../Helpers/toasts";
import NotificationsOffRoundedIcon from "@mui/icons-material/NotificationsOffRounded";
import { useTranslation } from "react-i18next";

const CostumAlert = styled(Alert)(({ show }) => ({
  display: show == "true" ? "flex" : "none",
  position: "absolute",
  left: "50px",
  bottom: "40px",
  width: "max-content",
  padding: "2px 10px",
  fontSize: "13px",
  lineHeight: "15px",
  alignItems: "center",
}));

export default function ColorBadge() {
  const { mainGroups, dispatchMainGroups } =
    React.useContext(MainGroupsContext);
  const [isHovered, setIsHovered] = React.useState(false);
  const [isCancel, setIsCancel] = React.useState(false);
  const [isClicked, setIsClicked] = React.useState(false);
  const { t } = useTranslation();

  const handleChange = useCallback(async () => {
    setIsClicked(true);
    useMainGroupStore.setState({
      isLoadingSubGroups: true,
    });
    const status = await approachingExams(dispatchMainGroups);
    useMainGroupStore.setState({
      isLoadingSubGroups: false,
    });
    if (!status) {
      toastError(t("Ma'lumot olib bolmadi"));
    }
  });

  const handleCancel = async () => {
    setIsClicked(false);
    useMainGroupStore.setState({
      isLoadingSubGroups: true,
    });
    const status = await subGroups(dispatchMainGroups);
    useMainGroupStore.setState({
      isLoadingSubGroups: false,
    });
    if (!status) {
      toastError(t("Ma'lumot olib bolmadi"));
    }
  };

  return (
    mainGroups.approaching_exams_count > 0 &&
    (isClicked ? (
      <Stack
        sx={(xs = { width: "30px" })}
        style={{ position: "relative" }}
        onClick={handleCancel}
        onMouseEnter={() => setIsCancel(true)}
        onMouseLeave={() => setIsCancel(false)}
      >
        <CostumAlert severity="info" show={`${isCancel}`}>
          {t("Barcha guruhlarga qaytish")}
        </CostumAlert>
        <IconButton aria-label="add to shopping cart">
          <Badge
            badgeContent={mainGroups.approaching_exams_count}
            color="warning"
          >
            <NotificationsOffRoundedIcon color="action" />
          </Badge>
        </IconButton>
      </Stack>
    ) : (
      <Stack
        style={{ position: "relative" }}
        onClick={handleChange}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CostumAlert severity="warning" show={`${isHovered}`}>
          {t("Imtixon yaqinlashgan guruhlar")}
        </CostumAlert>
        <IconButton color="secondary" aria-label="add an alarm">
          <Badge
            badgeContent={mainGroups.approaching_exams_count}
            color="warning"
          >
            <NotificationsActiveRoundedIcon color="action" />
          </Badge>
        </IconButton>
      </Stack>
    ))
  );
}
